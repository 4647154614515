














































































































import { namespace } from "vuex-class";
import { Component } from "vue-property-decorator";
import { AUTH_STORE_NAME, AuthStoreActions } from "@/store/auth.store";
import { ROUTE_HOME, ROUTE_LOGIN } from "@/router/routes";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { mixins } from "vue-class-component";
import User from "@/models/User.model";
import { UserType } from "@/enum/UserType.enum";
import { RegistrationSteps } from "@/enum/RegistrationSteps";
import { PaymentMethod } from "@/enum/PaymentMethod";
import EasyCard from "@/models/EasyCard.model";
import Application from "@/models/Application";
import { Gender } from "@/enum/UserTitle.enum";
import { UserDegree } from "@/enum/UserDegree";
import fileDownload from "js-file-download";
import { ApplicationBufferResponse } from "@/api/repositories/application.models";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  computed: {
    UserType() {
      return UserType;
    }
  },
  components: {
    RegisterMainComponent: () =>
      import(
        "@/components/Register.main.component.vue"
        ),
    RegisterEasyComponent: () =>
      import(
        "@/components/Register.easy.component.vue"
        ),
    RegisterPaymentComponent: () =>
      import(
        "@/components/Register.payment.component.vue"
        ),
    PrintDialog: () =>
      import(
        "@/components/PrintDialog.vue"
        )
  }
})
export default class RegisterView extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Action(AuthStoreActions.REGISTER_RESTAURATEUR)
  private registerAction!: (payload: {
    easyCards: EasyCard[],
    user: User,
    comment?: string,
    licensePlate?: string
  }) => Promise<ApplicationBufferResponse>;

  /**
   * user model that is used on the inputs for storing the values
   * @private
   */
  private user: User = User.parseFromObject({
    type: UserType.CUSTOMER,
    email: undefined,
    lastName: undefined,
    firstName: undefined,
    address: undefined,
    company: undefined,
    gender: Gender.NOT_SET,
    degree: UserDegree.NONE,
    newsletterSubscribed: false,
    status: true,
    phone: undefined,
    password: undefined,
    billingDetails: undefined
  });

  /**
   * Did the User Accept the Terms of Service
   * @private
   */
  private termsOfService: boolean = false;

  /**
   * Did the User Accept the Privacy Policy
   * @private
   */
  private agb: boolean = false;

  private showDownloadApplicationModal: boolean = false;

  private selectedApplication: Application = Application.parseFromObject({});

  /**
   * Downloadable Application Buffer
   * @private
   */
  private generatedBuffer: Uint8Array = new Uint8Array();

  private paymentMethod: PaymentMethod = PaymentMethod.debit;

  private isTypeCustomer: boolean = true;

  /**
   * Person Name who orders the easy Cards
   */
  public name: string = "";

  /**
   * License Plate of the Car
   */
  public licensePlate: string = "";

  /**
   * Count of Easy Cards to Order
   */
  public cardCount: number = 1;

  /**
   * Optional Comment for the Easy Cards
   */
  public comment?: string = "";

  private easyCards: EasyCard[] = [];

  private registrationSteps = [RegistrationSteps.userData, RegistrationSteps.cards, RegistrationSteps.payment];

  private currentRegistrationStep = this.registrationSteps[0];

  private changeRegistrationStep(step: RegistrationSteps) {
    this.currentRegistrationStep = step;
  }

  private onDownloadButtonPressed() {
    return fileDownload(new Blob([this.generatedBuffer]), `Antrag-${this.selectedApplication.referenceNumber}.pdf`);
  }

  private onUserTypeChanged(type: UserType) {
    this.user.type = type;
    if (type === UserType.CUSTOMER) {
      this.user.company = undefined;
    } else if (type === UserType.COMPANY) {
      this.user.firstName = undefined;
      this.user.lastName = undefined;
    }
  }

  private preventNextStep(step: RegistrationSteps) {
    if (this.currentRegistrationStep < step) return;
    this.changeRegistrationStep(step);
  }

  /**
   * redirects the user to the home view if he/she is logged in
   * @private
   */
  private created() {
    // Redirect to home if already logged in:
    if (this.$isLoggedIn()) {
      this.$router.push({ name: ROUTE_HOME });
    }
  }

  private onCloseDialogApplication() {
    this.showDownloadApplicationModal = false;
    this.$router.push({ name: ROUTE_LOGIN });
  }

  private syncDataUser(user: User) {
    this.user = user;
    this.changeRegistrationStep(RegistrationSteps.cards);
  }

  private syncDataEasyCards(name: string, comment: string, count: number) {
    this.name = name;
    this.comment = comment;
    this.cardCount = count;
    this.changeRegistrationStep(RegistrationSteps.payment);
  }

  /**
   * Check if Form is Valid
   * if valid try to Register a new Account and listen for API Responses
   * If Invalid return so Vuelidate handles Errors first
   * @private
   */
  private async doRegistration(user: User) {
    this.user = user;

    // Form is Valid try to Register a new Account
    try {
      // Create Easy Cards for the User
      for (let i = 0; i < this.cardCount; i++) {
        this.easyCards.push(EasyCard.parseFromObject({ name: this.name }));
      }

      // Register the user
      const { app, buffer } = await this.registerAction({
        easyCards: this.easyCards,
        user: user,
        comment: this.comment,
        licensePlate: this.licensePlate
      });

      // Set the Application to show in the Print Dialog
      this.selectedApplication = app;
      this.generatedBuffer = buffer;

      this.showDownloadApplicationModal = true;

      // Registration Successful toast
      this.$notifySuccessSimplified("GENERAL.NOTIFICATIONS.REGISTRATION_SUCCESSFUL");


    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 409: // Email or Company already exists
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.REGISTRATION_EMAIL_OR_COMPANY_ALREADY_EXISTS");
            break;
          case 422: // Wrong Format in Credentials
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS");
            break;
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.REGISTRATION_ERROR");
        }
      });
    } finally {
    }
  }
}
