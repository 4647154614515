import { render, staticRenderFns } from "./Register.view.vue?vue&type=template&id=ebc14ad0&scoped=true&"
import script from "./Register.view.vue?vue&type=script&lang=ts&"
export * from "./Register.view.vue?vue&type=script&lang=ts&"
import style0 from "./Register.view.vue?vue&type=style&index=0&id=ebc14ad0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebc14ad0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VDialog,VLayout,VRow})
